export function formatDateTime(dateTimeString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const dateTime = new Date(dateTimeString);
  const formattedDateTime = dateTime.toLocaleDateString("id-ID", options);
  return formattedDateTime;
}
