import React from "react";
import Logo from "../element/Logo";
import Button from "../element/Button";

export default function Notfound() {
  return (
    <div className="notfound">
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          <Logo isNotaris isGaruda isPPAT />
          <p className="p-green text-uppercase mt-5 fw-bold fs-3 mb-0">Kantor Notaris dan Notaris</p>
          <p className="p-green  fs-5">Winny Fatimah, S.H., M.Kn.</p>
          <p className="p-404">404</p>
          <p className="subline mb-0">Halaman tidak tersedia</p>
          <p>Silahkan kembali ke halaman utama</p>
          <Button isPrimary href={"/"} type={"link"} isLink isGreen className={"px-5"}>
            Home
          </Button>
        </div>
      </div>
    </div>
  );
}
