import React from "react";
import propTypes from "prop-types";
import Notaris from "../../assets/icon/ikatannotaris.svg";
import Ppat from "../../assets/icon/ikatanppat.svg";
import Garuda from "../../assets/icon/garuda.svg";
import "./style.scss";
export default function Logo(props) {
  const { isNotaris, isPPAT, isGaruda, isResponsive, isBackground, isScaleUp } = props;
  return (
    <div className="logo ">
      <div className={`d-flex gap-2 justify-content-center ${isScaleUp ? "scaleup" : ""}`}>
        <div className={`${isResponsive ? " image " : ""} ${isNotaris ? "mx-2" : "d-none"} ${isBackground ? "background" : ""}`}>
          <img src={Notaris} alt="Notaris" />
        </div>
        <div className={`${isResponsive ? " image " : ""} ${isGaruda ? "mx-2" : "d-none"} ${isBackground ? "background" : ""}`}>
          <img src={Garuda} alt="Garuda" />
        </div>
        <div className={`${isResponsive ? " image " : ""} ${isPPAT ? "mx-2" : "d-none"} ${isBackground ? "background" : ""}`}>
          <img src={Ppat} alt="PPAT" />
        </div>
      </div>
    </div>
  );
}

Logo.propTypes = {
  isNotaris: propTypes.bool,
  isPPAT: propTypes.bool,
  isGaruda: propTypes.bool,
  isResponsive: propTypes.bool,
  isBackground: propTypes.bool,
  isScaleUp: propTypes.bool,
};
