import React from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import "./style.scss";

export default function Button(props) {
  const className = [props.className];

  if (props.isPrimary) className.push("button-primary");
  if (props.isLink) className.push("button-link");
  if (props.isGrey) className.push("button-grey");
  if (props.isGreen) className.push("button-green");
  if (props.isAnimation) className.push("button-animation");
  if (props.isVisible) className.push("button-visible");
  if (props.isUp) className.push("button-up");
  if (props.isBorder) className.push("button-border");
  const onClick = () => {
    if (props.onClick) props.onClick();
  };

  if (props.isDisabled || props.isLoading) {
    if (props.isDisabled) className.push("disabled");
    return (
      <span className={className.join(" ")} style={props.style}>
        {props.isLoading ? (
          <>
            <span className="spinner-border spinner-border-sm me-3 p-white"></span>
            {!props.isLoadingText ? <span className="sr-only loading">Loading ...</span> : ""}
          </>
        ) : (
          props.children
        )}
      </span>
    );
  }

  if (props.type === "link") {
    if (props.isExternal) {
      return (
        <a href={`${props.href?.includes("https://") ? props.href : "https://" + props.href}`} className={className.join(" ")} style={props.style} target="blank">
          {props.children}
        </a>
      );
    } else {
      return (
        <Link to={props.href} className={className.join(" ")} style={props.style} onClick={onClick}>
          {props.children}
        </Link>
      );
    }
  }

  return (
    <button className={className.join(" ")} style={props.style} onClick={onClick}>
      {props.children}
      {props.isSpinner ? (
        <div className="lds-ring d-inline-props">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        ""
      )}
    </button>
  );
}

Button.propTypes = {
  type: propTypes.oneOf(["button", "link", "submit"]),
  onClick: propTypes.func,
  href: propTypes.string,
  target: propTypes.string,
  className: propTypes.string,
  isDisabled: propTypes.bool,
  isLoading: propTypes.bool,
  isLoadingText: propTypes.bool,
  isExternal: propTypes.bool,
  isAnimation: propTypes.bool,
  isSpinner: propTypes.bool,
  isPrimary: propTypes.bool,
  isLink: propTypes.bool,
  isGrey: propTypes.bool,
  isGreen: propTypes.bool,
  isVisible: propTypes.bool,
  isUp: propTypes.bool,
  isBorder: propTypes.bool,
};
