import React from "react";
import propTypes from "prop-types";
import "./style.scss";
import Button from "../Button";
import { formatDateTime } from "../../utils/time.function";
import Logo from "../Logo";
export default function Artikel(props) {
  const { onFunc } = props;
  const onClick = () => {
    if (onFunc) onFunc(props.idartikel);
  };
  const parser = new DOMParser();
  const doc = parser.parseFromString(props.description, "text/html");
  const plainText = doc.body.textContent;

  const img = props.description?.split("<img")[1].split('src="')[1].split('"')[0];

  let splitDesc = props.isJustOne ? 40 : 25;

  if (props.isLoading) {
    return (
      <div className={`loadingartikel ${props.isScreen ? "screen" : ""}`}>
        <p className="mt-4">Menunggu . . .</p>
        <span className="loader"></span>
        <p className="p-green">Kantor Notaris dan PPAT</p>
      </div>
    );
  }
  if (props.isNotFound) {
    return (
      <div className="notfoundartikel">
        <Logo isNotaris isPPAT />
        <h4 className="mt-4">Artikel Belum Tersedia</h4>
        <p className="p-base">Temukan Kebutuhan pelayanan NOTARIS dan PPAT anda di bawah ini</p>
        <Button type={"link"} isLink href={"/list"} isGreen isPrimary className={"mx-auto mt-3 p-base"}>
          Cek Selengkapnya
        </Button>
      </div>
    );
  }
  if (props.isFull) {
    return (
      <div className="artikel-list full mb-4 mb-md-0">
        <div className={`gridarea ${props.isJustOne ? "justone" : ""}`}>
          <div className="image position-relative ms-auto">
            <img src={img} alt="" />
            <div className="status p-base">NEW</div>
          </div>
          <div className="title">
            <p className="text-capitalize p-green p-base">{formatDateTime(props.date)}</p>
            <p className={`sub text-uppercase fw-bold mb-0 ${props.isJustOne ? "justone" : ""}`}>{props.title}</p>
          </div>
          <div className="desc">
            <p className="p-normal">{plainText?.split(":")[1].split(" ").slice(0, splitDesc).join(" ").concat("...")}</p>
            <div>
              <Button isPrimary isGreen isLink type={"link"} href={`/artikel/${props.idartikel}`} className={"px-4 py-1 p-small"} onClick={onClick}>
                Baca Lebih
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (props.isList) {
    return (
      <div className="artikel-list notfull ">
        <div className="gridarea2">
          <div className="date ">
            <p className="p-green p-small text-uppercase">{formatDateTime(props.date)}</p>
          </div>
          <div className="title">
            <p className="text-uppercase fw-bold p-base ">{props.title} </p>
          </div>
          <div className="read text-end">
            <Button isPrimary isLink type={"link"} href={`/artikel/${props.idartikel}`} className={"py-1 p-small d-inline-flex align-items-center"} onClick={onClick}>
              <span>Baca Lebih</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short p-green" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
              </svg>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Button.propTypes = {
  isFull: propTypes.bool,
  isList: propTypes.bool,
  img: propTypes.string,
  date: propTypes.string,
  title: propTypes.string,
  description: propTypes.string,
  onFunc: propTypes.func,
  idartikel: propTypes.string,
  isNotFound: propTypes.bool,
  isJustOne: propTypes.bool,
  isLoading: propTypes.bool,
  isScreen: propTypes.bool,
};
