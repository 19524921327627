import React, { Suspense, lazy } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/scss/style.scss";

import Loading from "./pages/Loading";
import Notfound from "./pages/notfound";

const Home = lazy(() => import("./pages/Home"));
const Artikel = lazy(() => import("./pages/Artikel"));
const Profil = lazy(() => import("./pages/Profil"));
const List = lazy(() => import("./pages/List"));
function App() {


  return (
    <>
      {/* <GlobalStateProvider> */} {/*This is the way for Save Global State*/}
      <Router>
        <Suspense fallback={<Loading />}>
          {/* This is the way for LazyLoad with suspense fallback*/}
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/list" element={<List />}></Route>
            <Route path="/artikel/:id" element={<Artikel />}></Route>
            <Route path="/profil" element={<Profil />}></Route>
            <Route path="*" element={<Notfound />}></Route>
          </Routes>
        </Suspense>
      </Router>
      {/* </GlobalStateProvider> */}
    </>
  );
}

export default App;
